import {inject, Injectable, LOCALE_ID} from '@angular/core';
import {
  UppyBody,
  UppyEvent,
  UppyInstance,
  UppyItem,
  UppyMeta,
} from '@app/shared/uppy';
import {Config, Language} from '@generated/models';
import Uppy from '@uppy/core';
import {Restrictions} from '@uppy/core/lib/Restricter';
import Italian from '@uppy/locales/lib/it_IT';
import Tus from '@uppy/tus';
import {UppyFile} from '@uppy/utils/lib/UppyFile';
import {md5} from 'js-md5';

import {
  combineLatest,
  filter,
  last,
  merge,
  Observable,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import {AMPLITUDE_DEVICE_ID} from '../amplitude/constants';
import {LANGUAGE} from '../constants';

@Injectable({
  providedIn: 'root',
})
export class UppyServices {
  private readonly language = inject(LANGUAGE);

  private sub!: Subject<UppyEvent>;
  private instancesSubs: Record<string, Subject<UppyEvent>> = {};

  private readonly instances: Partial<Record<UppyInstance, Uppy<any, any>>> =
    {};

  instance<M extends UppyMeta, B extends UppyBody>(
    id: UppyInstance,
    restrictions?: Partial<Restrictions>,
  ): Uppy<M, B> {
    return this.instances[id] ?? this.init(id, restrictions);
  }

  init<M extends UppyMeta, B extends UppyBody>(
    id: UppyInstance,
    restrictions?: Partial<Restrictions>,
  ): Uppy<M, B> {
    const uppy = new Uppy<M, B>({
      debug: true,
      id,
      restrictions: {...restrictions},
      ...(this.language === Language.It ? {locale: Italian} : {}),
    });
    uppy.use(Tus, {
      endpoint: '',
      storeFingerprintForResuming: false,
      limit: 10,
    });
    this.instances[id] = uppy;

    uppy
      .on('complete', async () => {
        this.instancesSubs[id]?.next({event: 'complete'});
        this.instancesSubs[id]?.complete();
        uppy.cancelAll();
      })
      .on('error', () => {
        console.log('error');
        this.sub?.error('');
      })
      .on('upload-error', () => {
        console.log('upload-error');
        this.sub?.error('');
      })
      .on('progress', (progress) => {
        this.instancesSubs[id]?.next({
          event: 'progress',
          progress,
        });
      })
      .on('upload-success', async (file) => {
        file &&
          this.instancesSubs[id]?.next({
            event: 'upload-success',
            meta: file.meta,
          });
      });

    return uppy;
  }

  uploadItems({
    files,
    configuration,
    deviceId,
  }: {
    files: UppyItem[];
    configuration: Config;
    deviceId: string | undefined;
  }): Observable<UppyEvent> {
    const intances: UppyInstance[] = files.reduce(
      (acc: UppyInstance[], file) => {
        const value = new Set(acc);
        value.add(file.instance);
        return Array.from(value);
      },
      [],
    );

    this.sub = new Subject<UppyEvent>();
    const destroy = this.sub.pipe(last());

    if (intances.length) {
      this.instancesSubs = intances.reduce(
        (
          acc: Partial<Record<UppyInstance, Subject<UppyEvent>>>,
          instance: UppyInstance,
        ) => {
          acc[instance] = new Subject<UppyEvent>();
          return acc;
        },
        {},
      );

      combineLatest(
        Object.keys(this.instancesSubs).reduce(
          (acc: Record<string, Observable<UppyEvent>>, key: string) => {
            acc[key] = this.instancesSubs[key].pipe(
              filter((v) => v.event === 'complete'),
            );
            return acc;
          },
          {},
        ),
      )
        .pipe(takeUntil(destroy))
        .subscribe(() => {
          // imposto il progress a 100 solo a trasferimento realmente concluso
          this.sub.next({event: 'progress', progress: 100});
          this.sub.next({event: 'complete'});
          this.sub.complete();
        });

      merge(
        ...Object.values(this.instancesSubs).map((val) =>
          val.pipe(filter((v) => v.event === 'upload-success')),
        ),
      )
        .pipe(takeUntil(destroy))
        .subscribe((value) => {
          this.sub.next(value);
        });

      combineLatest(
        Object.keys(this.instancesSubs).reduce(
          (acc: Record<string, Observable<UppyEvent>>, key: string) => {
            acc[key] = this.instancesSubs[key].pipe(
              startWith({event: 'progress'} as UppyEvent),
              filter((v) => v.event === 'progress'),
            );
            return acc;
          },
          {},
        ),
      )
        .pipe(takeUntil(destroy))
        .subscribe((instances) => {
          const totalSize = files.reduce((tot, file) => tot + file.size, 0);
          const sizes = files.reduce((acc: Record<string, number>, file) => {
            acc[file.instance] = (acc[file.instance] ?? 0) + file.size;
            return acc;
          }, {});
          const progress = Object.keys(sizes).reduce((acc: number[], key) => {
            acc.push((sizes[key] * (instances[key].progress ?? 0)) / 100);
            return acc;
          }, []);
          const totalProgress =
            (100 / totalSize) * progress.reduce((acc, p) => acc + p, 0);
          // anche se sono al 100% voglio impostare la progress al 99% in modo che rimanga il loader visibile e non compaia il success.
          this.sub.next({
            event: 'progress',
            progress: Math.max(0, Math.round(totalProgress) - 1),
          });
        });

      files.forEach((file) => {
        const uppy = this.instance(file.instance);
        const uppyFile = this.getFileByUploadId(uppy, file.uploadId);
        uppy.setFileMeta(uppyFile.id, {...file.meta, type: file.instance});
        const tusConfiguration = configuration?.tus.find(
          (c) => c.id === file.volume,
        );
        uppy.setFileState(uppyFile.id, {
          tus: {
            ...uppyFile.tus,
            ...{
              ...tusConfiguration,
              headers: deviceId ? {[AMPLITUDE_DEVICE_ID]: deviceId} : {},
            },
          },
        });
      });

      intances.forEach((i) => {
        this.instance(i).upload();
      });
      return this.sub;
    }

    setTimeout(() => {
      this.sub.next({event: 'progress', progress: 100});
      this.sub.next({event: 'complete'});
      this.sub.complete();
    }, 50);

    return this.sub;
  }

  getFileByUploadId(
    uppy: Uppy<UppyMeta, UppyBody>,
    uploadId: string,
  ): UppyFile<UppyMeta, UppyBody> {
    const uppyfile = uppy.getFiles().find((file) => md5(file.id) === uploadId);

    if (!uppyfile) {
      throw new Error();
    }
    return uppyfile;
  }
}
